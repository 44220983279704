import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { TMDialogProps } from "src/ui/Dialog/MDialog"

export function ReactivateBlockerDialog({
  open,
  onClose,
  onConfirm,
}: {
  open: TMDialogProps["open"]
  onClose: TMDialogProps["onClose"]
  onConfirm?: TMDialogProps["onConfirm"]
}) {
  const { navigate } = useRouter()

  const { t, langKeys } = useTranslate()

  return (
    <ConfirmDialog
      title={t(langKeys.reactivate_plan_title)}
      description={t(langKeys.reactivate_plan_message)}
      confirmLabel={t(langKeys.change_plan)}
      open={open}
      onClose={onClose}
      onConfirm={
        onConfirm || (() => navigate(Routes.AccountBilling.location()))
      }
    ></ConfirmDialog>
  )
}
